.aboutContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10rem;
  justify-content: center;
}

.aboutParagraphText {
  width: 35vw;
  text-indent: 1em;
  text-shadow: -5px 2px 10px grey;
  font-size: 1.3em;
}

.aboutParagraphContainer {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.image {
  width: 650px;
  border-radius: 150px;
  /* image is too wide with empty space*/
  margin-right: -10rem;
}
