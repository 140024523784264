.linkStyle {
  text-decoration-line: none;
  text-shadow: -5px 2px 10px grey;
  font-size: 1.5em;
  color: black;
}

.linkContainer {
  width: 100%;
  display: flex;
  /* top: 100; */
  justify-content: space-between;
  margin-top: 1.5rem;
  /* margin-left: 20rem; */
  /* margin: 1.5rem; */
}

.title {
  margin-left: 2rem;
}

.links {
  display: flex;
  justify-content: space-evenly;
  width: 45%;
  margin-right: 1rem;
}
