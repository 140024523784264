.homeContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
}

.eye {
  /* height: 40vh; */
  width: 50vw;
}
